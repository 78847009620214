import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from '@system'

const useStyles = makeStyles({
  componentName: {
    fontFamily: 'Arial',
    fontWeight: 'bolder',
    fontSize: '1.05rem',
    backgroundColor: 'purple',
    padding: '0 10px',
    verticalAlign: 'baseline',
  },
  path: {
    fontFamily: 'Arial',
    padding: '0 10px',
    backgroundColor: '#ccc',
    color: 'purple',
    fontWeight: 'bold',
  },
})

const ComponentNotFound = props => {
  const classes = useStyles()
  // if (process.env.NODE_ENV === 'production') {
  //   console.error(
  //     'ComponentNotFound attempted to render while in production. No missing components in production',
  //     props
  //   )
  // }
  return (
    <Text component="div">
      <Box
        bgcolor="error.main"
        color="error.contrastText"
        borderRadius={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontWeight="700"
        my={2}
        mx={1}
        p={1}
        minHeight="60px"
      >
        The component &nbsp;
        <Typography className={classes.componentName}>
          {props.blok.component}
        </Typography>
        &nbsp; is not defined. Add it to &nbsp;
        <Typography className={classes.path}>
          /src/components/blok/_components.js &nbsp;
        </Typography>
      </Box>
    </Text>
  )
}

export default ComponentNotFound
